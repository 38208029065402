/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const LanguagePage: React.FC = () => {
  const [languageData, setLanguageData] = useState<any>({});
  const status = false;

  useEffect(() => {
    async function fetchLanguageData() {
      const wfService = new Metadata();
      const response = await wfService.getLanguageData();
      setLanguageData(response?.languages);
    }
    fetchLanguageData();
  }, []);

  if (!languageData) {
    return <Loading />;
  }

  return (
    <div>
      {!!languageData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl ml-8 mr-8">
          <h3>Language Page</h3>
          <ul>
            {languageData.map((item: any) => (
              <div key={item.id}>
                - id : {item.id} - shortcode : {item.shortCode} - language :{' '}
                {item.languageName} - isPreffered : {String(status)}
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguagePage;
